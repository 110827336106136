/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../Scripts/TypeScript/rxjs/rx.lite.es6.d.ts"/>
/// <reference path="../Scripts/TypeScript/rxjs/rx.lite.experimental.d.ts"/>
/// <reference path="../RootStore.ts"/>

namespace Umbrella.Config {
    export interface FeatureFlag {
        name: string;
        displayName: string;
        enabled: boolean;
        description: string;
    }

    export interface KeycloakConfig {
        realm: string | undefined
        clientId: string | undefined,
        url: string | undefined
    }

    export interface Config {
        api: string;
        debug: boolean;
        signalRTransports: string[] | 'auto' | 'disabled';
        bugsnagApiKey: string;
        features: FeatureFlag[];
        experimentalAreaEnabled: boolean;
        managementRelativeUrl: string;
        managementErrorPath: string;
        managementLogPath: string;
        managementPortalPath: string;
        managementSynchronizedObjectPath: string;
        loginredirect: boolean;
        keycloak: KeycloakConfig | undefined
    }

    export interface ConfigLoadingEvent {
        type: 'ConfigLoadingEvent';
    }

    export interface ConfigLoadedEvent {
        type: 'ConfigLoadedEvent';
        config: Config;
    }

    export interface ConfigLoadErrorEvent {
        type: 'ConfigLoadErrorEvent';
        error: any;
    }

    export interface FeatureFlagChangedEvent {
        type: 'FeatureFlagChangedEvent';
        features: FeatureFlag[];
    }

    export interface ExperimentalAreaStateChanged {
        type: 'ExperimentalAreaStateChanged';
        enabled: boolean;
    }

    export type ConfigEvent =
        | ConfigLoadingEvent
        | ConfigLoadedEvent
        | ConfigLoadErrorEvent
        | FeatureFlagChangedEvent
        | ExperimentalAreaStateChanged;

    export interface ConfigState {
        config: Config;
        loadError: any;
    }

    const reducer: ObservableStore.EventHandler<ConfigState, ConfigEvent> = e => {
        switch (e.type) {
            case 'ConfigLoadingEvent':
                return s => ({ config: null, loadError: null });
            case 'ConfigLoadedEvent':
                return s => ({ config: e.config, loadError: null });
            case 'ConfigLoadErrorEvent':
                return s => ({
                    config: s.config,
                    loadError: e.error
                });
            case 'FeatureFlagChangedEvent':
                return s => ({
                    ...s,
                    config: {
                        ...s.config,
                        features: e.features
                    }
                });
            case 'ExperimentalAreaStateChanged':
                return s => ({
                    ...s,
                    config: {
                        ...s.config,
                        experimentalAreaEnabled: e.enabled
                    }
                });
        }
    };

    export const configStore = ObservableStore.create<ConfigState, ConfigEvent>(
        { config: null, loadError: null },
        reducer
    );

    export function changeExperimentalAreaState(enabled: boolean) {
        configStore.event$.onNext({
            type: 'ExperimentalAreaStateChanged',
            enabled
        });
    }

    export async function load() {
        configStore.event$.onNext({ type: 'ConfigLoadingEvent' });

        let config: Config = {
            api: 'http://api.umbrella.localhost',
            debug: false,
            signalRTransports: 'auto',
            bugsnagApiKey: undefined,
            features: [],
            experimentalAreaEnabled: false,
            managementRelativeUrl: 'beheer',
            managementErrorPath: '/#/beheer/monitoring/diagnostiek/servicebus/fouten/',
            managementLogPath: '/#/beheer/systeem/log?scopeId=',
            managementPortalPath: '/#/beheer/klantportaal/aanvragen/',
            loginredirect: undefined,
            managementSynchronizedObjectPath: '/#/beheer/monitoring/gesynchroniseerdeobjecten?query=',
            keycloak: undefined
        };
        let error;

        try {
            await $.getJSON(`/Config/config.json?_${Date.now()}`, configJson => {
                config = {
                    ...config,
                    ...configJson
                };
            });
        } catch (e) {
            error = e;
        }

        try {
            await $.getJSON('/Config/features.json', featuresJson => {
                config.features = featuresJson;
                config.features.forEach(f => {
                    const state = localStorage.getItem(f.name);
                    if (state === null) return;
                    f.enabled = state == 'true';
                });
            });
        } catch (e) {
            error = e;
        }

        const experimentalAreaUnlocked = localStorage.getItem('ExperimentalAreaUnlocked');
        if (experimentalAreaUnlocked && JSON.parse(experimentalAreaUnlocked) === 'true') {
            config.experimentalAreaEnabled = true;
        } else {
            config.experimentalAreaEnabled = false;
        }

        if (error) configStore.event$.onNext({ type: 'ConfigLoadErrorEvent', error });
        else configStore.event$.onNext({ type: 'ConfigLoadedEvent', config });
    }

    export function switchFeatureFlag(flagName: string, enabled: boolean) {
        const state = configStore.getState();

        if (!state || !state.config || !state.config.features) return;
        const features = state.config.features;

        const feature = features.find(x => x.name === flagName);
        if (feature) {
            const newFeatures = features.map(x => {
                if (x.name === flagName) x.enabled = enabled;
                return x;
            });

            configStore.event$.onNext({
                type: 'FeatureFlagChangedEvent',
                features: newFeatures
            });
        }
    }

    load();

    configStore.state$.subscribe(s => {
        window.clientConfig = s.config;
    });

    configStore.state$.skipWhile(x => !x.loadError).subscribe(s => {
        console.error(`Configuration load error: ${s.loadError}`);
    });
    configStore.state$.subscribe(s => {
        if (s.config && s.config.features && s.config.features.length) {
            s.config.features.forEach(f => {
                if (f.name === 'windowsAuthentication')
                {
                    localStorage.setItem(
                        f.name,
                        ((localStorage.getItem('windowsAuthentication') === 'true') || f.enabled).toString()
                    );
                }
                else
                {
                    localStorage.setItem(f.name, f.enabled.toString());
                }
            });
        }
    });


    function getPathWithTrailingSlash(path: string): string {
        if (!path || !path.length || path.endsWith('/') || path.includes('?')) return path;

        return `${path}/`;
    }

    export function getManagementLogRelativeUrl(): string {
        const logPath = getPathWithTrailingSlash(window.clientConfig.managementLogPath || '/#/beheer/systeem/log?scopeId=');
        return `/${getManagementRelativeUrl()}${logPath}`;
    }

    function getManagementRelativeUrl(): string {
        const subUrl = window.clientConfig.managementRelativeUrl || 'beheer';
        return getPathWithTrailingSlash(subUrl);
    }

    export function getPortalUrl(): string {
        return (
            (window.config &&
                window.config.modules &&
                window.config.modules.selfService &&
                window.config.modules.selfService.url) ||
            null
        );
    }

    export function makeAbsoluteApiUrl(requestUrl: string): string {
        return requestUrl.startsWith(window.clientConfig.api)
            ? requestUrl
            : ensureEndsWithSlash(window.clientConfig.api) + trimStartSlash(requestUrl);
    }

    function trimStartSlash(s: string): string {
        return (s.length === 0 || s[0] !== '/') ? s : s.substr(1);
    }

    function ensureEndsWithSlash(s: string): string {
        return s.endsWith('/') ? s : s + '/';
    }

    export function makeManagementErrorPath(id: Guid): string {
        const errorPath = getPathWithTrailingSlash(
            window.clientConfig.managementErrorPath || '/#/beheer/monitoring/diagnostiek/servicebus/fouten/'
        );
        return `/${getManagementRelativeUrl()}${errorPath}${id}`;
    }

    export function makeManagementLogPath(id: Guid): string {
        return `${getManagementLogRelativeUrl()}$${id}`;
    }

    export function makeManagementPortalPath(id: Guid): string {
        const portalPath = getPathWithTrailingSlash(
            window.clientConfig.managementPortalPath || '/#/beheer/klantportaal/aanvragen/'
        );
        return `/${getManagementRelativeUrl()}${portalPath}${id}`;
    }

    export function makeManagementSynchronizedObjectPath(id: Guid): string {
        const synchronizedObjectPath = getPathWithTrailingSlash(
            window.clientConfig.managementSynchronizedObjectPath ||
                '/#/beheer/monitoring/gesynchroniseerdeobjecten?query='
        );
        return `/${getManagementRelativeUrl()}${synchronizedObjectPath}${id}`;
    }

    $.ajaxSetup({
        xhrFields: {
            withCredentials: true
        },
        headers: { 'Access-Control-Allow-Origin': '*' }
    });
}
